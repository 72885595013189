import React, { useEffect } from 'react'
import { UseFormValidations } from '../../validations/UseFormValidation'
import { Modal } from 'react-bootstrap'
import Select from "react-select"
const CreateFacility = (props) => {
    const submit=()=>{
        data["hospitalId"]=data?.hospitalId?data?.hospitalId:0
        data["organizationId"]=1
        props?.submit(data)
    }
    const {data,errors,handleChange,handleNumberChange,handleAlphabetChange,handleSubmit,setValues}=UseFormValidations({
      initialValues: {
        hospitalName: "",
        shortName: "",
        address: "",
      },
      validationSchema: {
        hospitalName: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
        shortName: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
        address: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
      },
        submit:submit
    })
    const returnValue=(key)=>{
        return data?.[key]&&data?.[key]?data?.[key]:""
    }
    const ErrorValue=(key)=>{
    return errors?.[key]&&errors?.[key]?" form-control border border-danger":"form-control"
    }
    useEffect(()=>{
        if (props?.show?.hospitalId){
            setValues(props?.show)
        }
    },[props?.show?.hospitalId])
  return (
    <Modal  {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
    <Modal.Header >
      <Modal.Title>{props?.show?.hospitalId?"Edit Hospital":"Add Hospital"}</Modal.Title>
    </Modal.Header>
   <form onSubmit={handleSubmit}>
   <Modal.Body>
     <div className='row'>
<div>
    <label>Hospital Name <span className='text-danger'>*</span></label>
    <input className={ErrorValue("hospitalName")} placeholder='Hospital Name' value={returnValue("hospitalName")} onChange={handleAlphabetChange("hospitalName")}/>

</div>
<div>
    <label>Short Name <span className='text-danger'>*</span></label>
    <input className={ErrorValue("shortName")} placeholder='Short Name' value={returnValue("shortName")} onChange={handleAlphabetChange("shortName")}/>

</div>
<div>
    <label>Adress <span className='text-danger'>*</span></label>
    <textarea className={ErrorValue("address")} placeholder='Adress' value={returnValue("address")} onChange={handleChange("address")}/>

</div>


     </div>
    </Modal.Body>
    <Modal.Footer>
      <button className='btn border px-5' onClick={()=>props?.onHide()} >
        Close
      </button>
      <button className='bg_btn btn text-white px-5'>
        Save 
      </button>
    </Modal.Footer>
   </form>
  </Modal>
  )
}

export default CreateFacility