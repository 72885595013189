import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import {
  MdDashboard,
 
} from "react-icons/md";
import { GiSkills } from "react-icons/gi";

import {
 
  FaHome,
  FaHospital,
  FaHandHoldingMedical,
  FaCalendarAlt,
} from "react-icons/fa";
import { SiHyperskill } from "react-icons/si";
import { BiSolidUserDetail } from "react-icons/bi";
import { CiLogin } from "react-icons/ci";
import { Link, NavLink, Outlet } from "react-router-dom";
import logo from "../assets/images/navlogo.png";
import { AiOutlineCaretLeft, AiOutlineLogout } from "react-icons/ai";
import { GrSchedules, GrTransaction } from "react-icons/gr";
import { FaClipboardUser, FaRectangleList, FaUserDoctor } from "react-icons/fa6";
import { LuScanFace } from "react-icons/lu";
import { TbUserSquareRounded } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import { logOut } from "./ShareComp";
import { PiUserCirclePlusBold } from "react-icons/pi";
import { IoIosNotifications } from "react-icons/io";
import { RiMessage2Fill } from "react-icons/ri";
import { CgOrganisation } from "react-icons/cg";

const Navbar = () => {
  const [state, setState] = useState(false);
const [subMenu,setSubmenu]=useState(true)
  const link = [
    {
      linkName: "Dashboard",
      logo: <MdDashboard size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}}/>,
      url: "dashboard",
    },
    

    {
      linkName: "Transaction",
      logo: <GrTransaction size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />,
      url: "transaction/all",
    },
    {
      linkName: "PHM",
      logo: <FaRectangleList size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />,
      url: "phm/all",
    },
    // {
    //   linkName: "Schedule",
    //   logo: <FaCalendarAlt size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />,
    //   url: "appointment/schedule",
    // },

    {
      label: "Master",
      icon: <FaHome size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />,
      child: [
       { linkName: "Users", logo: <FaClipboardUser size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />, url: "users/all" },
        // { linkName: "Scan Packages", logo: <LuScanFace size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />, url: "scanpackage/All" },
        // { linkName: "Scan Packages", logo: <LuScanFace size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />, url: "scanpackage/All" },
        {
          linkName: "Subscription",
          logo: <TbUserSquareRounded size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />,
          url: "subscribtion/all",
        },
        {
          linkName: "Organization",
          logo: <CgOrganisation size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />,
          url: "organization/all",
        },
        { linkName: "Hospital", logo: <FaHospital size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />, url: "hospital/all" },
        { linkName: "Doctor", logo: <FaUserDoctor size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />, url: "doctor/all" },
        { linkName: "Patient", logo: <FiUsers size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />, url: "patient/all" },
        {
          linkName: "Speciality",
          logo: <FaHandHoldingMedical size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />,
          url: "speciality/all",
        }
      ],
      logo: <GiSkills />,
      url: "",
    },
    { linkName: "Log Out", logo: <AiOutlineLogout size={30} color="black" className="bg-white  border p-1" style={{borderRadius:"10px"}} />,url: "/" },
  ];
 
  const filterNav=()=>{
    let temp=[]
   let res= sessionStorage.getItem("roleId")==2? link?.filter((v)=>v.linkName!="PHM"):link
   res?.map(v=>{
      let te=v.child?.filter((c)=>c.linkName=="Users"||c.linkName=="Scan Packages"||c.linkName=="Subscription"||c?.linkName=="Organization")
      let te2=v?.child?.filter((c)=>c.linkName!="PHM")
     
      let te3=v.child?.filter((c)=>c.linkName!="Users"&&c.linkName!="Hospital"&&c.linkName!="Scan Packages"&&c.linkName!="Subscription")
      
     v["child"]=sessionStorage.getItem("roleId")==1?te:sessionStorage.getItem("roleId")==2?te2:sessionStorage.getItem("roleId")==3?te3:[]
      temp.push(v)
    })
   
  return temp
  }
 const roles=()=>{
  switch (sessionStorage.getItem("roleId")){
    case "1" :return "Super Admin"
    case "2" :return "Organization Admin"
    case "3" :return "Hospital Admin"
    case "4" :return "Doctor"
  }
 }
  return (
    <div  className="d-flex" >
     <div className="position-fixed" style={{zIndex:"1"}}>
     <Sidebar 
      transitionDuration={300}
       backgroundColor="#D8F8FF"
      collapsed={state}
      style={{ height: "100vh",position:"fixed", backgroundColor:"#D8F8FF" }}
      >
        <div
          className={`d-flex align-items-center border-0 border-bottom  gap-2 pointer mb-0 ptr`}
          onClick={() => setState(!state)}
          style={{height:"64px"}}
        >
          <img src={logo} className={`img-fluid sidebar ` }  />
        </div>
        {filterNav()?.map((v) => {
          return (
            <Menu className=""
             menuItemStyles={{
              button: {
                // the active class will be added automatically by react router
                // so we can use it to style the active menu item
                [`&.active`]: {
                  backgroundColor: '#539DF3',
                  color: '#ffff',
                  // borderRadius:"20px",
                height:"40px",
                // marginLeft:"5px",
                // marginRight:"5px"
                  
                },
              },
            }}
            >
              {v?.linkName && (
               <MenuItem  icon={v?.logo} onClick={v?.linkName=="Log Out"&&logOut} component={<NavLink className={"none"} to={v?.url} />} >{v?.linkName}
               
               </MenuItem>
              )}
              {v?.child && (
                <SubMenu className="positon-absolute" open={subMenu} onClick={()=>setSubmenu(!subMenu)}  icon={v?.icon} title="Master" label={v?.label}>
                  {v?.child?.map((c) => {
                    return (
                      <MenuItem  style={{zIndex:"1"}}   icon={c.logo} component={<NavLink className={"nab_bg"} to={c?.url} ></NavLink>}>
                       {c?.linkName}
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              )}
              
            </Menu>
          );
        })}
      </Sidebar>
     </div>

      <div className={state?"sidebar":"sidebar"} style={{width:"100%",marginLeft:state?"5rem":"15.5rem",transition:"ease-out"}}
         >
        <div className="  d-flex flex-wrap border-0 border-bottom align-items-center px-3" style={{height:"64px"}}>
          <div className="col-md-4"></div>
<div className="col-md-4 d-flex justify-content-end gap-4 ">
  {/* <input className="form-control  bg-light" placeholder="Search..." style={{borderRadius:"20px",height:"33px"}}/> */}
  
 
</div>
<div className="col-md-4 d-flex flex-wrap gap-3 justify-content-end align-items-center">
{/* <NavLink to={"/bluhealth/demo"} className="color3">Demo</NavLink > */}
<div><RiMessage2Fill size={18}/></div>
<div><IoIosNotifications size={20}/></div>
  <div>
    <div style={{fontSize:"14px",fontWeight:"600"}}>{sessionStorage.getItem("firstName")+" "+sessionStorage.getItem("lastName")}</div>
    <div style={{fontSize:"13px",fontWeight:"400"}}>{`[${roles()}]`}</div>
    </div>
    <div><PiUserCirclePlusBold size={25}/></div>
</div>
        </div>
      <div className="px-2 py-2"><Outlet/></div>
      </div>
    </div>
  );
};

export default Navbar;
