import React, { useEffect, useState } from "react";
import {
  checkRole,
  GetListById,
  icons,
  save,
  sortingTable,
  sortingTableNumbers,
} from "../../components/ShareComp";
import { url } from "../../services/Urls";
import Select from "react-select";
import ReactTableShare from "../../components/ReactTableShare";
import Add from "./Add";
const Subscribtion = () => {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [model, setModel] = useState(false);
  const [update, setUpdate] = useState([]);
  const getAllScan = async () => {
    let res = await GetListById(url.planPackage, { id: 0 });
    res?.map((v) => {
      v["services"] = v?.services && JSON.parse(v.services);
      
    });
    setList(res);
    console?.log(res,"res")
  };
  const submit = async (obj) => {
    const res = await save(url?.subscriptionSavePlan, obj);
    setUpdate(res);
    setModel(false);
  };

  const columns = [
    {
      name: "Plan Name",
      selector: (v) =>v.planName,
      width: "12rem",
      sortable: true,
     
    },
    {
      name: "Amount",
      cell: (v) =><div>{"Rs"+" "+ v?.amount}</div>,
      sortable: true,
      selector: row => row.amount,
      width: "",
    },
    {
      name: "Total Scans",
      cell: (v) => (
        <div
          className="bg_btn text-white px-2"
          style={{ borderRadius: "15rem" }}
        >
          {v?.totalScans}
        </div>
      ),
      selector:v=>v?.totalScans,
      width: "",
      sortable: true,
      
    },
    {
      name: "Plan Validity",
      cell: (v) => <div>{v?.planValidity + " " + "days"}</div>,
      selector: (v) =>v?.planValidity,
      sortable: true,
      width: "",
    },
    {
      name: "what's Includes",
      selector: (v) =>
        Array.isArray(v.services) &&
        v.services?.map((v) => (
          <div className="text-wrap">{v.label.toString(",")}</div>
        )),
      
      width: "14rem",
    },
    {
      name: "Description",
      selector: (v) => <div className="text-wrap">{v?.description}</div>,
      width: "14rem",
    },
    {
      name: "Action",
      selector: (v) => (
        <div className="ptr" onClick={() => setModel(v)}>
          {icons?.edit}
        </div>
      ),
      width: "",
    },
  ];

  const handleChang = () => (e) => {
    setSearch(e.target.value);
  };

  const listsearch = () => {
    return (
      <div className=" d-flex flex-wrap gap-1" style={{ width: "100%" }}>
        <div className="col">
          <input
            type="search"
            className="form-control"
            value={search != "" ? search : ""}
            onChange={handleChang()}
            placeholder="Search Subscription Plan..."
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    getAllScan();
  }, [update]);
  return (
    <div>
      <div className="d-flex flex-wrap py-1">
        <div className="col-md-6 px-2 heading_list ">Subscription Plan</div>
        <div className="col-md-6 d-flex flex-wrap gap-2 justify-content-end">
          {!checkRole() && (
            <button
              className="btn bg_btn col-auto text-white d-flex gap-2 align-items-center"
              onClick={() => setModel(!model)}
            >
              {icons.add}
              <span className="color2">Add Subscription</span>
            </button>
          )}
        </div>
      </div>
      <div className="py-2">
        <ReactTableShare
          dataTable={list}
          columns={columns || []}
          search1={listsearch}
          search={search}
        />
      </div>
      {model && (
        <Add show={model} onHide={() => setModel(false)} submit={submit} />
      )}
    </div>
  );
};

export default Subscribtion;
